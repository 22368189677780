<template>
  <div class="overlay details-publishing-modal">
    <div class="container">
      <router-link :to="'/details/' + $route.params.id" class="back"
        ><img
          class="desktop_form_close"
          src="@/assets/img/close.svg"
          alt="close"
      /></router-link>

      <label><strong>Global Work IDs For:</strong></label>
      <h1>{{ title }}</h1>

      <!-- <p>Here are the work ids for this musical work in other databases.</p> -->
      <div class="white-space"></div>
      <div class="white-space"></div>

      <div class="codes-table">
        <div
          class="publishing-code-block"
          v-for="[codeKey, codeValue] of codesArray"
          :key="codeKey"
        >
          <strong>
            {{ codeKey.toUpperCase() }}
          </strong>
          <span class="publishing-id-value">
            {{ codeValue }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailsPublishingIds",
  metaInfo: {
    title: "Global Identifiers",
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    codes: {
      type: Object,
      required: true,
    },
  },
  computed: {
    codesArray() {
      return Object.entries(this.codes).filter(([_, value]) => !!value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
